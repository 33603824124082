<template>
   <div class="col-12 px-0">
      <div class="card mb-0" :class="[index % 2 == 0 ? '' : 'bg-secondary', isSelecionado ? 'color-theme' : '']">
         <div class="card-body py-1 px-2">
            <div class="row m-0 text-center align-items-center">
               <div class="wpx-75 px-1 mx-auto mx-md-none" :class="view == 'Produto' ? 'd-block d-lg-none d-xl-block' : 'd-block d-md-block d-lg-none d-xxl-block'" v-if="view != 'Solicitacao-View'">{{ peca.referencia }}</div>
               <div class="wpx-100 px-1 mx-auto mx-md-none" :class="view == 'Produto' ? 'd-block d-lg-none d-xxl-block' : 'd-block d-md-none d-xxl-block'">
                  {{ String(peca.codigo).substring(0, 2) +"-"+ String(peca.codigo).substring(2, String(peca.codigo).length) }}
               </div>
               <div class="col-md px-1 text-center text-md-start mb-2 mb-md-0">
                  <a href="javascript:;" class="limitador-2" @click="verFoto"><i v-if="isSelecionado" class="fas fa-angle-right font-12 color-theme me-2"></i>{{ peca.nome }}</a>
               </div>
               <div class="wpx-75 px-1 d-none d-xxl-block py-1" v-if="view == 'Produto'">x{{ peca.qtdPecasMaquina }}</div>
               <div class="wpx-100 px-1 d-none d-xxl-block py-1" v-if="view == 'Produto'">
                  <small>R$ </small>{{ peca.preco == null ? '-' : parseFloat(peca.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
               </div>
               <div class="wpx-125 px-1 mx-auto mx-md-none">
                  <div v-if="view == 'Solicitacao-View'">x{{ peca.quantidade }}</div>
                  <div v-else-if="view == 'Solicitacao' || peca.preco != null" class="input-group input-quantity ps-2">
                     <button name="btn-minus" @click.stop="peca.quantidade = peca.quantidade - peca.qtdMult;" :disabled="peca.quantidade <= peca.qtdMult">-</button>
                     <input type="number" v-model="peca.quantidade" @blur="peca.quantidade = Math.ceil(peca.quantidade / peca.qtdMult) * peca.qtdMult" />
                     <button name="btn-plus" @click.stop="peca.quantidade = peca.quantidade + peca.qtdMult;">+</button>
                  </div>
               </div>
               <div class="wpx-75 ps-0 pe-2 mx-auto mx-md-0" v-if="view != 'Solicitacao-View'">
                  <button class="btn bg-danger text-white font-13 ms-2" @click="removePecaSelecao" v-if="view == 'Solicitacao-2'"><i class="far fa-trash"></i></button>
                  <button :class="view != 'Produto' || isSelecionado ? 'bg-theme' : 'bg-danger'" 
                     class="btn text-white font-13 ms-2" @click="addCarrinho" v-else-if="(view == 'Solicitacao' || peca.preco != null) && parseInt(peca.quantidade) > 0">
                     <i class="far" :class="view == 'Produto' ? 'fa-shopping-cart' : 'fa-plus'"></i>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Peca',
   props: ['peca', 'index', 'view', 'fotosSelecionadas'],
   data: function () {
      return {
         isSelecionado: false
      }
   },
   watch: {
      fotosSelecionadas : function (value) {
         this.isSelecionado = this.peca.fotos.length > 0 && JSON.stringify(value) == JSON.stringify(this.peca.fotos);
      }
   },
   methods: {
      verFoto : function () {
         if (this.view == 'Produto') {
            this.$emit('verFotos', this.peca.fotos)
         }
      },
      addCarrinho : function () {
         this.$emit('carrinho', JSON.parse(JSON.stringify(this.peca)))
         this.peca.quantidade = this.peca.qtdMult;
      },
      removePecaSelecao : function () {
         this.$emit('remover', this.index)
      }
   },
   mounted() {
      this.isSelecionado = this.peca.fotos != null && this.peca.fotos.length > 0 && JSON.stringify(this.fotosSelecionadas) == JSON.stringify(this.peca.fotos);
   }
}

</script>

<style scoped>

.card {
   border: 0;
   border-radius: 0;
}

</style>