<template>
   <div class="row">
      <!-- Vista explodida -->
      <div class="col-12 mb-3" v-if="produto">
         <label class="weight-500 text-dark text-uppercase font-11"><i class="fal fa-object-group color-theme me-1"></i> Vista explodida</label>
         <v-select title="Desktop" class="select-vista-explodida" :options="produto.vistas" label="nome" v-model="vistaExplodida.codigo" :reduce="v => v.codigo" ref="selectVistaExplodida" placeholder="Selecione uma vista explodida"
            @input="getPecasVistaExplodida" @open="destacarVistaSelecionada">
            <template v-slot:no-options>Sem resultados</template>
         </v-select>
         <select title="Mobile" class="form-control" v-model="vistaExplodida.codigo" @change="getPecasVistaExplodida">
            <option value="" disabled selected>Selecione uma vista</option>
            <option v-for="(option, index) in produto.vistas" :key="index" :value="option.codigo">{{ option.nome }}</option>
         </select>
      </div>

      <div v-if="view != 'Solicitacao-View' && vistaExplodida.pecas.length == 0">
         <div class="error__content text-center mt-100">
            <div class="error__number">
               <h1 class="mt-50 mb-50"><i class="fal fa-object-group"></i></h1>
            </div>
            <span class="d-none d-sm-block">Nenhuma vista selecionada.</span>
            <h4 class="mt-3 mb-100">Selecione acima uma das vistas explodidas disponíveis para o produto.</h4>
         </div>
      </div>
      <div class="col-12 d-flex position-relative mb-10" v-else-if="view != 'Solicitacao-View'">
         <div id="vistaExplodida" class="ratio ratio-21x9 container-zoom" @contextmenu.prevent="panzoom.instance.pause()" @click="panzoom.instance.resume()">
            <panZoom selector="#zoomable" :options="{bounds: true, maxZoom: 2.5, minZoom: 0.3, zoomDoubleClickSpeed: 1, initialZoom: 1, transformOrigin: null}" @init="onInitPanZoom">
               <div id="zoomable" :class="panzoom.hasPoints ? '' : 'w-100 h-100'" ref="vistaExplodida">
                  <img :src="vistaExplodida.fotoExplodida == null ? '' : vistaExplodida.fotoExplodida"
                     :class="panzoom.hasPoints ? '' : 'w-100 h-100'" :style="panzoom.hasPoints ? '' : 'object-fit: contain'" />
                  <div v-for="(peca, index) in vistaExplodida.pecas" :key="index" :style="{top: peca.y +'px', left: peca.x +'px', width: peca.tamanho +'px', height: peca.tamanho +'px'}" 
                     :class="[vistaExpPeca.selected != null && peca.codigo == vistaExpPeca.selected.codigo ? 'activeImgPoint' : '', peca.y != null && peca.x != null ? '' : 'd-none']" 
                     @click="setPecaVistaExplodida($event, peca)" @touchend="setPecaVistaExplodida($event, peca)" class="iactiveImgPoint"></div>
               </div>
            </panZoom>
         </div>
         <button class="btn btn-zoomReset px-3" @click="zoomPanZoom(null)" v-if="panzoom.transform != 'none'"><i class="fal fa-expand-wide"></i></button>
         <button class="btn btn-zoomIn px-3" @click="zoomPanZoom(true)" v-if="panzoom.instance != null"><i class="fal fa-search-plus"></i></button>
         <button class="btn btn-zoomOut px-3" @click="zoomPanZoom(false)" v-if="panzoom.instance != null"><i class="fal fa-search-minus"></i></button>
         <button class="btn btn-download" @click="downloadVistaExp" v-if="view == 'Produto'">
            <span>Baixar vista<br>explodida</span><span><i class="far fa-chevron-double-down"></i></span>
         </button>

         <!-- Box peca -->
         <div class="pointBox normal fadeInUp" v-if="vistaExpPeca.selected != null" :style="{top: vistaExpPeca.cTop +'px', left: vistaExpPeca.cLeft +'px'}">
            <div class="pointBoxClose">
               <div class="pointBoxCloseIcon" @click="setPecaVistaExplodida($event, null)">
                  <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="black" stroke-width="1"><path d="M2 14L14 2M2 2l12 12"></path></g></svg>
               </div>
            </div>

            <div class="ratio ratio-1x1">
               <img loading="lazy" :src="vistaExpPeca.selected.fotos[0] == null ? '' : vistaExpPeca.selected.fotos[0].url" @error="imageError" />
            </div>
            
            <div class="pointBoxTxt">
               <div class="pointBoxTitle">{{ vistaExpPeca.selected.nome }}</div>
               <div class="pointBoxDesc">
                  <div class="row m-0">
                     <div class="col-6 px-1">
                        <span class="limitador">Cód. {{ String(vistaExpPeca.selected.codigo).substring(0, 2) +"-"+ String(vistaExpPeca.selected.codigo).substring(2, String(vistaExpPeca.selected.codigo).length) }}</span>
                     </div>
                     <div class="w-max-content px-1">|</div>
                     <div class="col px-1">
                        <span class="limitador"><small>R$ </small>{{ vistaExpPeca.selected.preco == null ? '-' : parseFloat(vistaExpPeca.selected.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                     </div>
                  </div>
               </div>
               <div class="input-group input-quantity justify-content-center mb-2" v-if="vistaExpPeca.selected.preco != null">
                  <button name="btn-minus" @click.stop="vistaExpPeca.selected.quantidade = vistaExpPeca.selected.quantidade - vistaExpPeca.selected.qtdMult;" :disabled="vistaExpPeca.selected.quantidade <= vistaExpPeca.selected.qtdMult">-</button>
                  <input type="number" v-model="vistaExpPeca.selected.quantidade" @blur="vistaExpPeca.selected.quantidade = Math.ceil(vistaExpPeca.selected.quantidade / vistaExpPeca.selected.qtdMult) * vistaExpPeca.selected.qtdMult">
                  <button name="btn-plus" @click.stop="vistaExpPeca.selected.quantidade = vistaExpPeca.selected.quantidade + vistaExpPeca.selected.qtdMult;">+</button>
               </div>
               <button class="btn bg-danger text-white font-13" @click="addCarrinho(vistaExpPeca.selected)" v-if="view == 'Produto'"><i class="far fa-shopping-cart me-1"></i> Carrinho</button>
               <button class="btn bg-danger text-white font-13" @click="addCarrinho(vistaExpPeca.selected)" v-else-if="view == 'Solicitacao'"><i class="far fa-plus me-1"></i> Selecionar</button>
            </div>
         </div>
      </div>

      <!-- Lista de pecas -->
      <div class="col-12">
         <div class="row">
            <div v-if="view != 'Solicitacao-View' && vistaExplodida.pecas.length > 0" :class="view == 'Produto' ? 'col-lg-6 col-xxl-7' : 'col-lg-6'">
               <div class="row">
                  <div class="col-12">
                     <input type="text" class="form-control" v-model="config.pesquisaPeca" placeholder="Busque por nome ou código" />
                  </div>
                  <div class="col-12 mt-2">
                     <div class="card bg-secondary rounded-0 border-0 mb-0">
                        <div class="card-body p-2">
                           <div class="row m-0 align-items-center text-center text-dark weight-500">
                              <div class="wpx-75 px-1" :class="view == 'Produto' ? 'd-block d-lg-none d-xl-block' : 'd-block d-md-block d-lg-none d-xxl-block'">Ref.</div>
                              <div class="wpx-100 px-1" :class="view == 'Produto' ? 'd-block d-lg-none d-xxl-block' : 'd-block d-md-none d-xxl-block'">Código</div>
                              <div class="col px-1 text-start">Nome da peça</div>
                              <div class="wpx-100 px-1 d-none d-xxl-block" v-if="view == 'Produto'"></div>
                              <div class="wpx-100 px-1 d-none d-xxl-block" v-if="view == 'Produto'">Preço</div>
                              <div class="wpx-125 px-1 d-none d-md-block d-lg-none d-xxl-block">Quantidade</div>
                              <div class="wpx-75 px-1 d-none d-md-block"></div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-12 container-scroll container-search mb-15">
                     <div class="row m-0">
                        <peca v-for="(peca, index) in vistaExplodida.pecas" :key="index" :peca="peca" :index="index" :view="view" :fotosSelecionadas="config.fotosPecaSelecionada" 
                           @carrinho="addCarrinho($event)" @verFotos="setFotosPecaSelecionada($event)" />
                        <div class="col-12 px-0 py-5 text-center" v-if="vistaExplodida.pecas == null || vistaExplodida.pecas.length == 0">Nenhuma peça encontrada</div>
                     </div>
                  </div>
               </div>
            </div>

            <div v-if="view == 'Produto' && vistaExplodida.pecas.length > 0" class="col-lg-6 col-xxl-5">
               <div class="product__details-nav d-sm-flex">
                  <div class="product__details-thumb pe-sm-1 w-100 mb-1 align-self-center">
                     <div class="tab-content" id="productThumbContent-2">
                        <foto v-for="(foto, index) in config.fotosPecaSelecionada" :key="'2-BODY'+ index" :index="index" :foto="foto" :location="'BODY'" :container="'PEÇA'" 
                           @visualizar="verMidia($event)" /> 

                        <div v-if="config.fotosPecaSelecionada == null || config.fotosPecaSelecionada.length == 0" class="tab-pane fade show active" role="tabpanel">
                           <div class="product__details-nav-thumb ratio ratio-1x1"><img src="#" @error="imageError"></div>
                        </div>
                     </div>
                  </div>
                  <ul class="nav nav-tabs flex-sm-column align-self-center mb-1" id="productThumbTab-2" role="tablist">
                     <foto v-for="(foto, index) in config.fotosPecaSelecionada" :key="'2-HEADER'+ index" :index="index" :foto="foto" :location="'HEADER'" :container="'PEÇA'" />

                     <li v-if="config.fotosPecaSelecionada == null || config.fotosPecaSelecionada.length == 0" class="nav-item" role="presentation">
                        <button class="nav-link active ratio ratio-1x1" id="thumbOne-tab-2" data-bs-toggle="tab" type="button" role="tab" aria-selected="true">
                           <img src="#" @error="imageError">
                        </button>
                     </li>
                  </ul>
               </div>
            </div>

            <div v-else-if="(view == 'Solicitacao' || view == 'Solicitacao-View') && pecasSelecionadas.length > 0" :class="view == 'Solicitacao' ? 'col-lg-6' : 'col-12'">
               <div class="row">
                  <div class="col-12 my-1 text-center" v-if="view != 'Solicitacao-View'">
                     <h6 class="font-15 color-theme mt-2"><i class="far fa-check-circle me-1"></i> Peças selecionadas</h6>
                  </div>
                  <div class="col-12 mt-2">
                     <div class="card bg-secondary rounded-0 border-0 mb-0">
                        <div class="card-body p-2">
                           <div class="row m-0 align-items-center text-center text-dark weight-500">
                              <div class="wpx-75 px-1 d-block d-md-block d-lg-none d-xxl-block" v-if="view != 'Solicitacao-View'">Ref.</div>
                              <div class="wpx-100 px-1 d-block d-md-none d-xxl-block">Código</div>
                              <div class="col px-1 text-start">Nome da peça</div>
                              <div class="wpx-125 px-1 d-none d-md-block">Quantidade</div>
                              <div class="wpx-75 px-1 d-none d-md-block" v-if="view != 'Solicitacao-View'"></div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-12 container-scroll mb-15">
                     <div class="row m-0">
                        <peca v-for="(peca, index) in pecasSelecionadas" :key="index" :peca="peca" :index="index" :view="view == 'Solicitacao' ? (view +'-2') : view" 
                           @remover="removePecaSelecao($event)" />
                        <div class="col-12 px-0 py-5 text-center" v-if="pecasSelecionadas.length == 0">Nenhuma peça selecionada</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12 d-none" v-if="view == 'Produto'">
         <section ref="html2Pdf">
            <vistaExpPDF :produto="produto" :vistaExp="vistaExplodida" />
         </section>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import vuePDF from 'html2pdf.js'
import peca from '@/components/produtos/Peca.vue'
import foto from '@/components/produtos/Foto.vue'
import vistaExpPDF from '@/components/produtos/VistaExplodidaPDF.vue'

export default {
   name: 'VistaExplodida',
   props: ['view', 'produto', 'pecasSelecionadas'],
   data : function () {
      return {
         panzoom: {'instance': null, 'transform': null, 'hasPoints': true},
         vistaExplodida: {'codigo': null, 'nome': null, 'fotoExplodida': null, 'pecas': []},
         config: {'pesquisaPeca': null, 'fotosPecaSelecionada': []},
         vistaExpPeca: {'selected': null, 'cTop': 0, 'cLeft': 0},
      }
   },
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   watch: {
      'config.pesquisaPeca' : function (val) {
         var value = val.toLowerCase()

         $(".container-search .card").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      },
   },
   components: {
      peca, foto, vistaExpPDF
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      onInitPanZoom : function (panzoomInstance) {
         setTimeout(() => {
            this.panzoom = {'instance': panzoomInstance, 'transform': JSON.parse(JSON.stringify(panzoomInstance.getTransform())), 'hasPoints': this.panzoom.hasPoints}
            panzoomInstance.pause()
         }, 250);
      },
      zoomPanZoom : function (zoomIn) {
         let config = this.panzoom.instance.getTransform()

         if (zoomIn == null) {
            this.panzoom.instance.moveTo(this.panzoom.transform.x, this.panzoom.transform.y)
            this.panzoom.instance.zoomAbs(this.panzoom.transform.x, this.panzoom.transform.y, 1)

            if (!this.panzoom.hasPoints) {
               setTimeout(() => this.$refs.vistaExplodida.style.transform = 'none', 250);
            }
         } else if (zoomIn == true) {
            this.panzoom.instance.smoothZoom(config.x, config.y, 1.5);
            
         } else if (zoomIn == false) {
            this.panzoom.instance.smoothZoom(config.x, config.y, 0.5);
         }
      },
      setPecaVistaExplodida : function (e, peca) {
         let x;
         let y;

         if (e.type == 'touchend') {
            var touch = e.changedTouches[0];
            x = touch.clientX;
            y = touch.clientY;

         } else if (e.type == 'click') {
            x = e.clientX;
            y = e.clientY;
         }

         this.vistaExpPeca = {'selected': peca, 'cTop': y, 'cLeft': x}
      },
      destacarVistaSelecionada : function () {
         this.$nextTick(() => {
            let dropdown = this.$refs.selectVistaExplodida.$el.querySelector('.vs__dropdown-menu');
            let selectedOption = dropdown.querySelector('.vs__dropdown-option--selected');

				if (selectedOption) {
					dropdown.scrollTop = selectedOption.offsetTop - dropdown.clientHeight / 2 + selectedOption.clientHeight / 2;
				}
			});
      },
      downloadVistaExp : function () {
         vuePDF(this.$refs.html2Pdf, {
            margin: 0,
            filename: 'Vista Explodida '+ this.produto.codigo +'.pdf',
            image: {'type': 'jpeg', 'quality': 1},
            pagebreak: {'avoid': 'tr'},
            html2canvas: {'letterRendering': true, 'useCORS': true},
            jsPDF: {'unit': 'in', 'format': 'letter', 'orientation': 'portrait'}
         })
      },
      getPecasVistaExplodida : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.setPecaVistaExplodida(window, null)

         $(".container-search .card").css('display', '')
         
			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getVistaExplodida',
            params: {
               codigo: this.vistaExplodida.codigo
            }
			}).then(response => {
            this.vistaExplodida = {'codigo': response.data.codigo, 'pecas': response.data.pecas, 'nome': response.data.nome, 'fotoExplodida': response.data.fotoExplodida}
            this.config = {'pesquisaPeca': null, 'fotosPecaSelecionada': []}

            if (response.data.pecas.length > 0 && this.view == 'Produto') {
               this.config = {'pesquisaPeca': null, 'fotosPecaSelecionada': response.data.pecas[0].fotos}

            } else if (response.data.pecas.length > 0 && this.view == 'Solicitacao') {
               this.vistaExplodida.pecas.forEach(p => {
                  p.quantidade = 1;
                  p.qtdMult = 1;
               });
            }
			}).catch((error) => {
				if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               })
            } else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
            setTimeout(() => {
               this.panzoom.hasPoints = (this.vistaExplodida.pecas.find(peca => peca.y != null && peca.x != null) != null)
               
               if (this.panzoom.hasPoints) {
                  this.panzoom.instance.moveTo(this.panzoom.transform.x, this.panzoom.transform.y)
                  this.panzoom.instance.zoomAbs(this.panzoom.transform.x, this.panzoom.transform.y, 1)

               } else if (this.view != 'Solicitacao-View') {
                  this.$refs.vistaExplodida.style.transform = 'none'
               }

               this.$store.dispatch('alternarTelaCarregamento', false)
            }, 250);
			});
      },
      setFotosPecaSelecionada : function (fotos) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.config.fotosPecaSelecionada = fotos;

         if (window.innerWidth < 992) {
            document.querySelector('#productThumbContent-2').scrollIntoView(false);
         }

         setTimeout(() => {
            $('#productThumbTab-2 button[data-bs-target="#pills-0PEÇA"]').click();
            this.$store.dispatch('alternarTelaCarregamento', false)
         }, 250);
      },
      verMidia : function (event) {
         this.$emit('verMidia', event)
      },
      removePecaSelecao : function (index) {
         this.$emit('removePeca', index)
      },
      addCarrinho : function (event) {
         this.$emit('addCarrinho', event)
         this.setPecaVistaExplodida(window, null)
      },
      eventScroll : function () {
         this.setPecaVistaExplodida(window, null)
      }
   },
   mounted() {
      setTimeout(() => {
         window.addEventListener('scroll', this.eventScroll)
      }, 100);
   },
   beforeDestroy() {
      window.removeEventListener('scroll', this.eventScroll)
   }
}

</script>

<style scoped>

.v-select {
   height: 42px;
}

.btn-download {
   position: absolute;
   display: flex;
   align-items: center;
   background-color: #848484;
   color: #fff;
   line-height: 1;
   font-size: 14px;
   padding: 6px 12px;
   text-align: start;
}

.btn-download {
   top: 8px;
   right: 20px;
}

.btn-download span {
   display: block;
}

.btn-download span:last-child {
   padding-left: 8px;
}

#productThumbTab-2  {
   max-width: 100vw;
   min-width: 95px;
   overflow-y: hidden;
   overflow-x: auto;
   flex-wrap: unset;
}

@media (min-width: 576px) {
   #productThumbTab-2  {
      max-height: 445px;
      overflow-y: auto;
      overflow-x: hidden;
      flex-wrap: nowrap;
   }
}

.vue-pan-zoom-scene img {
   cursor: pointer;
}

</style>