<template>
   <section>
      <div class="container p-3 h-page">
         <div class="row align-items-center mb-4">
            <div class="col-3">
               <img src="@/assets/cliente/img/logo/logo-black.png" height="40" alt="logo" />
            </div>
            <div class="col-9 text-end">
               <h6 class="mb-0"><strong>{{ produto.nome }}</strong></h6>
               <span>Cód. {{ String(produto.codigo).substring(0, 2) +"-"+ String(produto.codigo).substring(2, String(produto.codigo).length) }}</span>
               <strong class="mx-1">-</strong>
               <span>Gerado em: {{ data.getDate().toString().padStart(2, "0") +'/'+ (data.getMonth() + 1).toString().padStart(2, "0") +'/'+ data.getFullYear() }}</span>
               <span> {{ data.getHours().toString().padStart(2, "0") +':'+ data.getMinutes().toString().padStart(2, "0") }}</span>
            </div>
         </div>

         <div class="row align-items-center text-center">
            <img :src="vistaExp.fotoExplodida == null ? '' : vistaExp.fotoExplodida" class="mb-2" width="10" alt="vistaExp" @error="imageError" />
            <h5 class="mb-0"><strong>{{ vistaExp.nome }}</strong></h5>
            <span>Cód. {{ String(vistaExp.codigo).substring(0, 2) +"-"+ String(vistaExp.codigo).substring(2, String(vistaExp.codigo).length) }}</span>
         </div>
      </div>

      <div class="container px-3">
         <div class="row align-items-center">
            <div class="col-12">
               <h5 class="text-center mb-4"><strong>Peças</strong></h5>
            </div>

            <div class="col-12 mt-1">
               <div class="table-content table-responsive">
                  <table class="table border-0">
                     <tbody>
                        <tr class="border-0 text-center" v-for="(peca, index) in vistaExp.pecas" :key="index" :class="index % 2 == 0 ? 'bg-secondary' : ''">
                           <td>{{ peca.referencia }}</td>
                           <td>{{ String(peca.codigo).substring(0, 2) +"-"+ String(peca.codigo).substring(2, String(peca.codigo).length) }}</td>
                           <td class="text-start">{{ peca.nome }}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>

            <div class="col-12 py-5 text-center" v-if="vistaExp.pecas == null || vistaExp.pecas.length == 0">Nenhuma peça encontrada</div>
         </div>
      </div>
   </section>
</template>

<script>

export default {
   name: 'VistaExplodidaPDF',
   props: ['produto', 'vistaExp'],
   data: function () {
      return {
         data: new Date()
      }
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>

<style scoped>

.row {
   margin: 0;
}

.row > * {
   padding: 0;
}

img[alt="vistaExp"] {
   max-height: 900px;
   object-fit: contain;
}

.card {
   border-color: #0000;
   border-radius: 0;
}

.h-page {
   min-height: 1100px;
}

h5 strong {
   font-size: 18px !important;
}

*:not(td, h5) {
   font-size: 14px !important;
}

td {
   font-size: 13px !important;
   border: 0 !important;
   padding: 8px 0 !important;
}

</style>